import styled from "styled-components"

import { media } from "../../helpers/variables"

export const Section = styled.section`
    padding-top: 160px;
`

export const Pagination = styled.nav`
    display: flex;
    padding: 25px 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    ${media.md} {
        flex-direction: row;
        padding: 55px 0;
    }

    button {
        margin: 10px 0;
        text-transform: uppercase;

        ${media.md} {
            margin: 0 10px;
        }
    }
`

export const WorksContainer = styled.div`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1380px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

export const Contacts = styled.div`
    padding: 45px 0 90px 0;
`

export const FormWrapper = styled.div`
    max-width: 605px;
    margin: 0 auto;
    width: 100%;
`
