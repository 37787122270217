import styled from "styled-components"

export const Wrapper = styled.div`
    padding: 50px 0 110px 0;
    position: relative;
    max-width: 735px;
    margin: 0 auto;
    width: 100%;

    img {
        position: absolute;
        opacity: 0.1;
        left: -50px;
        top: -70px;
    }

    h5 {
        line-height: 1.6;
    }
`
