import React, { useState, useEffect } from "react"
import * as PropTypes from "prop-types"
import { useLocation } from "react-use"
import Fade from "react-reveal/Fade"
import { navigateTo } from "gatsby"

import Work from "../../components/Work"
import Quote from "../../components/Quote"
import Text from "../../components/shared/Text"
import Button from "../../components/shared/Button"
import Social from "../../components/shared/Social"
import ContactForm from "../../components/ContactForm"
import { CATEGORIES, ROUTES } from "../../helpers/variables"
import {
    Section,
    Contacts,
    Pagination,
    FormWrapper,
    WorksContainer,
} from "./styled"

const getCategory = search => {
    if (search && search.toLowerCase().includes("inter"))
        return CATEGORIES.INTER
    if (search && search.toLowerCase().includes("concept"))
        return CATEGORIES.CONCEPT

    return CATEGORIES.ARCH
}

const WorksSection = ({ works }) => {
    const location = useLocation()
    const { search } = location

    const [category, setCategory] = useState(CATEGORIES.ARCH)

    const handleCategory = category => {
        setCategory(category)

        if (category === CATEGORIES.INTER) return navigateTo(ROUTES.INTER)
        if (category === CATEGORIES.CONCEPT) return navigateTo(ROUTES.CONCEPT)
        return navigateTo(ROUTES.ARCH)
    }

    useEffect(() => {
        setCategory(getCategory(search))
    }, [search])

    return (
        <Section>
            <Fade top>
                <Text variant="h1" align="center">
                    ПРОЕКТЫ
                </Text>
            </Fade>

            <Fade>
                <Pagination>
                    <Button
                        borderStyle="rounded"
                        style={{ outline: "none" }}
                        onClick={() => handleCategory(CATEGORIES.ARCH)}
                        variant={
                            category === CATEGORIES.ARCH
                                ? "contained"
                                : "outlined"
                        }
                    >
                        {CATEGORIES.ARCH}
                    </Button>
                    <Button
                        borderStyle="rounded"
                        style={{ outline: "none" }}
                        onClick={() => handleCategory(CATEGORIES.INTER)}
                        variant={
                            category === CATEGORIES.INTER
                                ? "contained"
                                : "outlined"
                        }
                    >
                        {CATEGORIES.INTER}
                    </Button>
                    <Button
                        borderStyle="rounded"
                        style={{ outline: "none" }}
                        onClick={() => handleCategory(CATEGORIES.CONCEPT)}
                        variant={
                            category === CATEGORIES.CONCEPT
                                ? "contained"
                                : "outlined"
                        }
                    >
                        {CATEGORIES.CONCEPT}
                    </Button>
                </Pagination>
            </Fade>

            <WorksContainer>
                {category !== CATEGORIES.CONCEPT &&
                    works
                        .filter(w => getCategory(w.category) === category)
                        .map(work => <Work work={work} key={work.id} />)}

                {category === CATEGORIES.CONCEPT &&
                    works.length > 2 &&
                    works
                        .filter(w => getCategory(w.category) === category)
                        .slice(0, 2)
                        .map(work => <Work work={work} key={work.id} />)}

                {category === CATEGORIES.CONCEPT && works.length > 2 && (
                    <Quote text="Дом для конкретного человека должен быть «подогнан» под его имидж, образ жизни, привычки. Дом астронома, к примеру, немыслим без мини-обсерватории, дом яхтсмена – без пирса, дом моряка должен отражать дух морских странствий, дом художника – это, в первую очередь, наполненная светом творческая мастерская или мини-галерея его работ." />
                )}

                {category === CATEGORIES.CONCEPT &&
                    works.length > 4 &&
                    works
                        .filter(w => getCategory(w.category) === category)
                        .slice(2, 4)
                        .map(work => <Work work={work} key={work.id} />)}

                {category === CATEGORIES.CONCEPT && works.length > 4 && (
                    <Quote text="Одним из приемов в создании архитектурного образа является замещение способом ассоциации, что подразумевает установление эмоционально-чувственных связей между явлением окружающего мира и объектом. Насколько цельным будет архитектурный образ – это зависит лишь от степени гениальности его создателя." />
                )}

                {category === CATEGORIES.CONCEPT &&
                    works.length > 5 &&
                    works
                        .filter(w => getCategory(w.category) === category)
                        .slice(4)
                        .map(work => <Work work={work} key={work.id} />)}
            </WorksContainer>

            <div style={{ backgroundColor: "hsla(218, 8%, 79%, 1)" }}>
                <div className="container">
                    <Contacts>
                        <Fade>
                            <Text
                                variant="h2"
                                align="center"
                                style={{ letterSpacing: "inherit" }}
                            >
                                Желаете заказать проект?
                            </Text>
                        </Fade>

                        <FormWrapper>
                            <Fade bottom cascade>
                                <Social
                                    variant="horizontal"
                                    style={{ marginBottom: 50 }}
                                />
                                <ContactForm />
                            </Fade>
                        </FormWrapper>
                    </Contacts>
                </div>
            </div>
        </Section>
    )
}

WorksSection.propTypes = {
    works: PropTypes.arrayOf(
        PropTypes.shape({
            mainImage: PropTypes.object.isRequired,
        }).isRequired
    ),
}

export default WorksSection
