import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"
import * as PropTypes from "prop-types"

import Text from "../shared/Text"
import { transitions } from "../../helpers/variables"
import { Wrapper, ImageWrapper, TextWrapper } from "./styled"
import { get, splitStringFromJSON } from "../../helpers/utils"

const Work = ({ work }) => {
    const { id, name, description, address } = work

    const fluid = get(work, "mainImage.childImageSharp.fluid", {})

    console.log(work)
    return (
        <Wrapper>
            <ImageWrapper>
                <Fade>
                    <Img
                        alt={name}
                        fluid={fluid}
                        style={{ height: "100%" }}
                        imgStyle={{
                            objectFit: "cover",
                            transition: transitions.main,
                        }}
                    />
                </Fade>
                <Link to={`/${id}`} />
            </ImageWrapper>

            <Fade bottom>
                <TextWrapper>
                    <div>
                        <Text variant="h4" weight="bold">
                            {name}
                        </Text>
                        <Text variant="p">
                            {splitStringFromJSON(description).map(
                                (line, idx) => (
                                    <span
                                        key={`${line}-${idx}`}
                                        style={{ display: "block" }}
                                    >
                                        {line}
                                    </span>
                                )
                            )}
                        </Text>
                    </div>

                    <Text variant="p">{address}</Text>
                    <Link to={`/${id}`} />
                </TextWrapper>
            </Fade>
        </Wrapper>
    )
}

Work.propTypes = {
    work: PropTypes.shape({
        id: PropTypes.string.isRequired,
        mainImage: PropTypes.shape({
            fluid: PropTypes.object,
        }).isRequired,
    }).isRequired,
}

export default Work
