import React from "react"
import * as PropTypes from "prop-types"

import Text from "../shared/Text"
import { Wrapper } from "./styled"
import QuoteImg from "../../assets/images/quote.png"

const Quote = ({ text }) => {
    return (
        <Wrapper>
            <img src={QuoteImg} loading="lazy" alt="кавычки" />
            <Text variant="h5">{text}</Text>
        </Wrapper>
    )
}

Quote.propTypes = {
    text: PropTypes.string.isRequired,
}

export default Quote
