import React from "react"
import { graphql } from "gatsby"
import * as PropTypes from "prop-types"
import ReactNotification from "react-notifications-component"
import "react-notifications-component/dist/theme.css"

import { get } from "../helpers/utils"
import SEO from "../components/shared/Seo"
import WorksSection from "../sections/Works"
import Layout from "../components/shared/Layout"

const Works = props => {
    const { allWorksJson } = get(props, "data")
    const works = allWorksJson.edges.map(e => e.node)

    return (
        <Layout>
            <ReactNotification />
            <SEO title="Проекты" />
            <WorksSection works={works} />
        </Layout>
    )
}

Works.propTypes = {
    location: PropTypes.object.isRequired,
    data: PropTypes.shape({
        allWorksJson: PropTypes.object,
    }),
}

export default Works

export const pageQuery = graphql`
    query {
        allWorksJson {
            edges {
                node {
                    id
                    name
                    address
                    description
                    category
                    mainImage {
                        childImageSharp {
                            # Here we query for *multiple* image thumbnails to be
                            # created. So with no effort on our part, 100s of
                            # thumbnails are created. This makes iterating on
                            # designs effortless as we change the args
                            # for the query and we get new thumbnails.

                            fluid(maxWidth: 1040) {
                                ...GatsbyImageSharpFluid
                            }
                            # fixed {
                            #     ...GatsbyImageSharpFixed
                            # }
                        }
                    }
                }
            }
        }
    }
`
