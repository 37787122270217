import styled from "styled-components"

import { media } from "../../helpers/variables"

export const Wrapper = styled.div`
    flex: 0 100%;
    display: flex;
    margin-bottom: 40px;
    flex-direction: column;

    ${media.md} {
        flex: 0 48.5%;
        margin-bottom: 115px;
    }
`

export const ImageWrapper = styled.div`
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;

    a {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    div {
        height: 100%;
    }

    ${media.md} {
        height: 300px;
    }
    ${media.lg} {
        height: 440px;
    }

    ${media.lg} {
        :hover {
            img {
                transform: scale(1.1);
            }
        }
    }
`

export const TextWrapper = styled.div`
    flex: 1; /* height 100% */
    display: flex;
    cursor: pointer;
    position: relative;
    flex-direction: column;
    justify-content: space-between;

    a {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    h4 {
        padding-top: 20px;
        letter-spacing: 1px;
    }

    p {
        max-width: 650px;
    }
`
